@font-face {
  src: url("./../../fonts/ff-din/DIN.ttf") format("truetype");
  font-family: 'FF-DIN'; }

@font-face {
  src: url("./../../fonts/ff-din/DINBold.ttf") format("truetype");
  font-family: 'FF-DIN-BOLD'; }

@font-face {
  src: url("./../../fonts/Freon.ttf") format("truetype");
  font-family: 'Freon'; }

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box; }

a {
  text-decoration: none;
  color: #FFFFFF; }

a, button, input, textarea {
  outline: none; }

button, input, textarea {
  font-family: 'FF-DIN'; }

input::-webkit-input-placeholder {
  font-family: 'FF-DIN'; }

button {
  cursor: pointer; }

h1, h2, h3, h4 {
  font-family: 'FF-DIN'; }

p {
  line-height: 1.2; }

body {
  overflow-x: hidden;
  font-family: 'FF-DIN';
  position: relative; }

.nowrap {
  white-space: nowrap; }

.App {
  min-width: 320px; }

.astro-btn {
  display: inline-block;
  width: 157px;
  font-weight: bold;
  border: 1px solid #FFFFFF;
  color: black;
  text-align: center;
  padding: 10px 0;
  font-size: 14px;
  border-radius: 20px;
  text-transform: uppercase;
  background-color: #FFFFFF;
  cursor: pointer; }
  .astro-btn:hover {
    background-color: transparent;
    color: #FFFFFF; }

.blur {
  filter: blur(4px);
  filter: -webkit-blur(4px); }

.grayscale {
  filter: grayscale(1);
  filter: -webkit-grayscale(1); }

.orange-bg {
  background-color: #F68847; }
  .orange-bg .close {
    background-color: #01cfd4 !important; }

.gray-bg {
  background-color: #425E76; }
  .gray-bg .close {
    background-color: #FA5C6B !important; }

.red-bg {
  background-color: #f44030; }
  .red-bg .close {
    background-color: #01cfd4 !important; }

.green-bg {
  background-color: #D9EE55; }
  .green-bg .close {
    background-color: #f44030 !important; }

.pink-bg {
  background-color: #FA5C6B; }
  .pink-bg .close {
    background-color: #01cfd4 !important; }

.black-bg {
  background-color: black; }
  .black-bg .close {
    background-color: #D9EE55 !important; }

.blue-bg {
  background-color: #01cfd4; }
  .blue-bg .close {
    background-color: #FF8841 !important; }

.gray-bg2:after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #2E5D76;
  pointer-events: none;
  opacity: 0.6; }

.pink-bg2:after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #f21226;
  pointer-events: none;
  opacity: 0.6; }

.orange-bg2:after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #FF8841;
  pointer-events: none;
  opacity: 0.6; }

.green-bg2:after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #D0ED42;
  pointer-events: none;
  opacity: 0.6; }

.red-bg2:after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #f44030;
  pointer-events: none;
  opacity: 0.6; }

.blue-bg2:after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #00A1AF;
  pointer-events: none;
  opacity: 0.6; }

.bg-video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: radial-gradient(rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.77) 100%); }
  .bg-video img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: relative;
    z-index: -1; }

.disabled-btn {
  background-color: #dbdbdb;
  border: 1px solid #dbdbdb;
  color: #abb5ab;
  cursor: not-allowed; }
  .disabled-btn:hover {
    background-color: #dbdbdb;
    border: 1px solid #dbdbdb;
    color: #abb5ab; }

.header {
  position: absolute;
  top: 45px;
  left: 40px;
  right: 40px;
  z-index: 1;
  display: grid;
  grid-template-columns: repeat(2, max-content);
  justify-content: space-between; }
  .header .astro-logo {
    max-width: 320px; }
    .header .astro-logo img {
      width: 100%; }
  .header .coming-logo {
    max-width: 650px; }
    .header .coming-logo img {
      width: 100%; }

@media only screen and (max-width: 991px) {
  .header .coming-logo {
    max-width: 520px; } }

@media only screen and (max-width: 768px) {
  .header {
    top: 25px;
    left: 20px;
    right: 20px; }
    .header .puma-logo img {
      width: 80px; }
    .header .coming-logo {
      max-width: 430px; } }

@media only screen and (max-width: 640px) {
  .header .astro-logo {
    max-width: 220px; }
  .header .puma-logo img {
    width: 60px; } }

@media only screen and (max-width: 640px) {
  .header .coming-logo {
    max-width: 340px; } }

@media only screen and (max-width: 380px) {
  .header .coming-logo {
    max-width: 195px; } }

@media only screen and (max-width: 480px) {
  .header .coming-logo {
    max-width: 195px; } }

@media screen and (orientation: landscape) and (max-width: 840px) and (max-height: 640px) {
  .header {
    top: 25px; }
    .header .astro-logo {
      max-width: 250px; }
    .header .coming-logo {
      max-width: 320px; }
    .header .puma-logo img {
      width: 60px; } }

.nav {
  position: fixed;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  z-index: 4;
  display: flex;
  align-items: flex-end;
  transition: all 1s;
  flex-direction: column; }
  .nav a {
    height: 116px;
    width: 116px;
    font-size: 14px;
    display: grid;
    align-items: end;
    padding: 14px 5px;
    text-align: center;
    justify-items: center;
    vertical-align: text-bottom;
    position: relative;
    transition: 0.6s;
    text-transform: uppercase;
    font-family: 'FF-DIN-BOLD';
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden; }
    .nav a span:nth-child(3) {
      position: absolute;
      transform: translateX(100%);
      opacity: 0; }
    .nav a:hover {
      width: 232px; }
      .nav a:hover img {
        display: block; }
      .nav a:hover span {
        min-width: 90px; }
      .nav a:hover span:nth-child(2) {
        display: none; }
      .nav a:hover span:nth-child(3) {
        position: unset;
        transform: translateX(0);
        opacity: 1;
        transition: 0.6s; }
  .nav .close-btn {
    display: none;
    position: absolute;
    color: #FFFFFF;
    font-size: 16px;
    top: -28px;
    right: 15px;
    background-color: transparent;
    border: none;
    font-weight: 800;
    text-transform: uppercase; }
  .nav .menu-btn {
    display: none;
    position: absolute;
    color: #FFFFFF;
    transform: rotate(-90deg);
    background-color: black;
    border: none;
    font-size: 16px;
    width: 100px;
    top: 28px;
    padding: 12px 0;
    right: -72px;
    text-transform: uppercase;
    border-radius: 0 0 6px 6px; }

.nav-active a {
  width: 15px; }
  .nav-active a img {
    display: none; }
  .nav-active a span:nth-child(2) {
    display: none; }

.nav-active .active {
  width: 30px; }

@media only screen and (max-width: 991px) {
  .nav a {
    height: 100px;
    width: 100px; }
    .nav a img {
      width: 40px; }
  .nav-active a {
    width: 15px; } }

@media only screen and (max-width: 1024px) {
  .nav {
    position: fixed;
    top: unset;
    right: 0;
    left: 0;
    bottom: 20px;
    bottom: 38px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    transform: translateX(-100%); }
    .nav a {
      width: auto; }
      .nav a span:nth-child(3) {
        display: none; }
      .nav a:hover {
        width: auto; }
        .nav a:hover span:nth-child(2) {
          display: block; }
    .nav .menu-btn {
      display: block; }
    .nav .close-btn {
      display: block; }
    .nav .active {
      width: auto; }
  .mob-nav-active {
    transform: translateX(0%); }
  .nav-active a span:nth-child(2) {
    display: block; }
  .nav-active a img {
    display: block; } }

@media only screen and (orientation: portrait) and (max-width: 768px) {
  .nav {
    bottom: 20px; } }

@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .nav {
    bottom: 54px; } }

@media screen and (orientation: landscape) and (max-width: 840px) and (max-height: 620px) {
  .nav {
    position: fixed;
    top: unset;
    right: 0;
    left: 0;
    bottom: 20px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    transform: translateX(-100%); }
    .nav a {
      width: auto; }
      .nav a:hover {
        width: auto; }
        .nav a:hover span:nth-child(2) {
          display: block; }
        .nav a:hover span:nth-child(3) {
          display: none; }
    .nav .menu-btn {
      display: block; }
    .nav .close-btn {
      display: block; }
    .nav .active {
      width: auto; }
  .mob-nav-active {
    transform: translateX(0%); }
  .nav-active a span:nth-child(2) {
    display: block; }
  .nav-active a img {
    display: block; } }

.home {
  min-height: 100vh;
  position: relative;
  padding: 110px 0 85px 0;
  display: grid;
  justify-content: center;
  align-items: center; }
  .home .banner {
    text-align: center;
    color: #FFFFFF; }
    .home .banner .banner-heading {
      font-size: 215px;
      line-height: 0.6;
      opacity: 0.1;
      font-family: 'Freon';
      font-weight: 400;
      right: -19px;
      position: relative; }
    .home .banner .banner-subheading {
      margin: 36px 0 20px;
      font-size: 44px; }
    .home .banner .banner-para {
      max-width: 630px;
      margin: 0 auto;
      font-size: 26px;
      line-height: 1.2; }

.home-red {
  background: repeating-radial-gradient(#FF5B4F 0%, #C93227 100%);
  transition: all 0.4s; }

.home-blue {
  transition: all 0.4s;
  background: repeating-radial-gradient(#01cfd4 0%, #1C555F 100%); }

.home-pink {
  background: repeating-radial-gradient(#FA5C6B 0%, #960815 100%);
  transition: all 0.4s; }

.home-green {
  transition: all 0.4s;
  background: repeating-radial-gradient(#D9EE55 0%, #899A21 100%); }

.home-orange {
  transition: all 0.4s;
  background: repeating-radial-gradient(#F68847 0%, #933600 100%); }

.home-black {
  transition: all 0.4s;
  background: repeating-radial-gradient(#242422 0%, black 100%); }

.home-gray {
  transition: all 0.4s;
  background: repeating-radial-gradient(#425E76 0%, #0D2B46 100%); }

@media only screen and (max-width: 991px) {
  .home .banner .banner-heading {
    font-size: 170px;
    right: -12px; }
  .home .banner .banner-subheading {
    font-size: 32px;
    margin: 15px 0 10px; }
  .home .banner .banner-para {
    max-width: 490px;
    font-size: 20px; } }

@media only screen and (max-width: 640px) {
  .home .banner .banner-heading {
    font-size: 93px;
    right: -8px; }
  .home .banner .banner-subheading {
    font-size: 28px;
    max-width: 220px;
    text-align: left;
    position: relative;
    top: -30px;
    margin: 0; }
  .home .banner .banner-para {
    display: none; } }

@media screen and (orientation: landscape) and (max-width: 840px) and (max-height: 640px) {
  .home {
    padding: 80px 0 40px; }
    .home .banner .banner-heading {
      font-size: 93px;
      right: -8px; }
    .home .banner .mobo-heading {
      font-size: 28px;
      display: block;
      max-width: 220px;
      text-align: left;
      position: relative;
      top: -30px; }
    .home .banner .banner-subheading {
      display: none; }
    .home .banner .banner-para {
      display: none; } }

.train {
  position: fixed;
  top: 50%;
  left: 0;
  right: 15px;
  transform: translate(120%, -50%);
  z-index: 3;
  transition: 1s; }
  .train .train-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    align-content: center;
    background-color: black; }
    .train .train-container .train-astro {
      position: relative;
      height: 0;
      text-align: center;
      color: #FFFFFF;
      overflow: hidden;
      transition: all 0.8s; }
      .train .train-container .train-astro img {
        width: 100%;
        height: 100%;
        object-fit: cover; }
      .train .train-container .train-astro .active-img2 {
        display: none; }
      .train .train-container .train-astro .astro-wrapper {
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        z-index: 1;
        transform: translateY(-50%);
        padding: 0 12px; }
      .train .train-container .train-astro .train-btn {
        opacity: 0;
        pointer-events: none;
        width: 156px;
        padding: 12px;
        margin: 25px auto 0;
        background-color: #FFFFFF;
        border-radius: 20px;
        color: #000;
        font-size: 14px;
        font-weight: bold;
        display: block;
        text-transform: uppercase; }
      .train .train-container .train-astro:hover {
        transform: scale(1.1);
        z-index: 1; }
        .train .train-container .train-astro:hover:after {
          background-color: transparent; }
        .train .train-container .train-astro:hover .train-btn {
          opacity: 1;
          transition: all 0.8s;
          pointer-events: auto; }
        .train .train-container .train-astro:hover .active-img1 {
          display: none; }
        .train .train-container .train-astro:hover .active-img2 {
          display: block; }
      .train .train-container .train-astro .astro {
        font-size: 14px;
        text-transform: uppercase; }
      .train .train-container .train-astro .train-name {
        font-size: 26px;
        text-transform: uppercase; }

.anime-train {
  transform: translate(0%, -50%);
  transition: 1s;
  display: grid;
  align-items: center; }
  .anime-train .train-container .train-astro {
    height: 464px; }

@media only screen and (max-width: 991px) {
  .train {
    position: absolute;
    right: 0;
    top: 0;
    transform: translate(0, -100%); }
    .train .train-container {
      grid-template-columns: repeat(3, minmax(100px, 1fr)); }
      .train .train-container .train-astro:hover {
        transform: scale(1); }
      .train .train-container .train-astro .train-name {
        font-size: 20px; }
  .anime-train {
    transform: translate(0, 0%);
    top: 135px; } }

@media only screen and (max-width: 640px) {
  .train {
    right: 0;
    top: 0;
    transform: translate(0, -100%); }
    .train .train-container {
      display: block; }
  .anime-train {
    top: 80px;
    transform: translate(0%, 0%);
    transition: 2.5s; } }

@media screen and (orientation: landscape) and (max-width: 840px) {
  .train {
    right: 0;
    top: 0;
    transform: translate(0, -100%); }
  .anime-train {
    top: 80px;
    transform: translate(0%, 0%);
    transition: 2.5s; } }

.pop-up {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: transparent;
  z-index: 5; }
  .pop-up .cam-access {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 693px;
    border-radius: 6px;
    border: 2px solid #FFFFFF;
    color: #FFFFFF;
    transform: translate(-50%, -50%);
    padding: 105px 0;
    z-index: 1; }
    .pop-up .cam-access .access-para {
      font-size: 26px;
      max-width: 450px;
      line-height: 1.2;
      margin: 0 auto 12px;
      text-align: center; }
    .pop-up .cam-access .access-sub-para {
      max-width: 450px;
      margin: 0 auto;
      text-align: center; }
    .pop-up .cam-access .checkbox {
      display: none; }
      .pop-up .cam-access .checkbox:checked + .accept-policy:before {
        content: "\2714"; }
    .pop-up .cam-access .checkbox + .accept-policy {
      font-size: 16px;
      display: block;
      text-align: center;
      margin: 30px 0 0;
      padding: 0 5px; }
      .pop-up .cam-access .checkbox + .accept-policy::before {
        content: "";
        display: inline-block;
        text-align: center;
        width: 22px;
        height: 22px;
        border-radius: 4px;
        background-color: transparent;
        border: 1px solid #FFFFFF;
        margin: 0 20px 0 0;
        vertical-align: middle; }
      .pop-up .cam-access .checkbox + .accept-policy a {
        text-decoration: underline; }
    .pop-up .cam-access .pop-up-btn {
      margin: 55px 0 0;
      display: grid;
      grid-template-columns: repeat(2, max-content);
      justify-content: center;
      grid-column-gap: 15px; }
      .pop-up .cam-access .pop-up-btn .cancel-btn {
        background-color: transparent;
        color: #FFFFFF; }

.email-pop .cam-access {
  min-height: 300px;
  padding: 0;
  display: grid;
  align-content: center;
  grid-gap: 25px; }
  .email-pop .cam-access .thanks-message {
    text-align: center; }
    .email-pop .cam-access .thanks-message h3 {
      font-size: 32px; }
    .email-pop .cam-access .thanks-message p {
      width: 80%;
      margin: 15px auto 0; }
    .email-pop .cam-access .thanks-message span {
      display: inline-block; }
  .email-pop .cam-access .email-pop-message {
    margin: 30px auto 0;
    padding: 0 48px;
    text-align: center; }
    .email-pop .cam-access .email-pop-message span {
      display: inline-block; }
  .email-pop .cam-access.green-bg .email-pop-message {
    color: black; }
  .email-pop .cam-access .email-pop-loading {
    margin: 0 auto;
    position: relative;
    display: inline-block;
    text-align: center; }
    .email-pop .cam-access .email-pop-loading:after {
      position: absolute;
      left: 100%;
      content: '...';
      margin-left: .25em;
      animation: loadingEllipsis 2s linear infinite; }
  .email-pop .cam-access .close {
    position: absolute;
    right: 15px;
    top: 15px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #2E5D76;
    text-align: center;
    padding: 4px 0 0;
    cursor: pointer; }
  .email-pop .cam-access .inputs {
    box-shadow: 0 0 0 4px rgba(255, 255, 255, 0.5);
    border: none;
    border-radius: 28px;
    width: 75%;
    margin: 0 auto;
    font-size: 16px;
    padding: 14px 15px;
    background-color: white; }
    .email-pop .cam-access .inputs::-webkit-input-placeholder {
      color: rgba(0, 0, 0, 0.5);
      font-family: 'FF-DIN', sans-serif; }
  .email-pop .cam-access .astro-btn {
    margin: 0 auto; }
  .email-pop .cam-access .form-item {
    text-align: center;
    margin: 0 0 24px; }

@keyframes loadingEllipsis {
  0% {
    content: ''; }
  25% {
    content: '.'; }
  50% {
    content: '..'; }
  75% {
    content: '...'; } }

@media only screen and (max-width: 768px) {
  .pop-up.mobo-pop {
    display: block; }
    .pop-up.mobo-pop .cam-access {
      padding: 40px 0; }
  .pop-up .cam-access {
    width: auto;
    right: 35px;
    left: 35px;
    padding: 20px 0;
    transform: translate(0, -50%); }
    .pop-up .cam-access .access-para {
      padding: 0 8px;
      font-size: 20px;
      text-align: center; } }

@media only screen and (max-width: 480px) {
  .pop-up .cam-access .pop-up-btn {
    display: block; }
    .pop-up .cam-access .pop-up-btn .astro-btn {
      width: 90%;
      margin: 15px auto 0;
      display: block; }
    .pop-up .cam-access .pop-up-btn .cancel-btn {
      margin: 0 auto 0; }
  .pop-up .thanks-message span {
    display: inline !important; }
  .pop-up .email-pop-message span {
    display: inline !important; } }

.complete-training {
  min-height: 100vh;
  position: relative;
  display: grid;
  justify-content: center;
  align-items: center;
  padding: 140px 0 30px 0; }
  .complete-training .complete-train {
    display: flex;
    justify-content: center;
    max-width: 1380px;
    min-width: 60%;
    margin: 0 auto;
    padding: 15px;
    align-items: center; }
    .complete-training .complete-train .flex-col {
      flex: auto; }
      .complete-training .complete-train .flex-col:first-child {
        margin-right: 10%;
        max-width: 50%; }
    .complete-training .complete-train .share-img img {
      width: 100%;
      height: 100%; }
    .complete-training .complete-train .train-session {
      color: #FFFFFF; }
      .complete-training .complete-train .train-session .slick-slider {
        margin: 0 0 15px;
        display: none; }
        .complete-training .complete-train .train-session .slick-slider img {
          width: 100%; }
        .complete-training .complete-train .train-session .slick-slider .slick-arrow {
          display: none !important; }
        .complete-training .complete-train .train-session .slick-slider .slick-dots {
          position: unset; }
          .complete-training .complete-train .train-session .slick-slider .slick-dots li {
            height: 17px;
            width: 17px;
            border-radius: 50%; }
            .complete-training .complete-train .train-session .slick-slider .slick-dots li button {
              border: 2px solid #FFFFFF;
              height: 15px;
              width: 15px;
              border-radius: 50%; }
              .complete-training .complete-train .train-session .slick-slider .slick-dots li button:before {
                display: none; }
            .complete-training .complete-train .train-session .slick-slider .slick-dots li.slick-active button {
              border: 2px solid #FA5C6B; }
      .complete-training .complete-train .train-session .session-heading {
        font-size: 20px;
        font-weight: 400; }
        .complete-training .complete-train .train-session .session-heading + p {
          margin: 12px 0 50px; }
      .complete-training .complete-train .train-session .exercise-active {
        color: #FA5C6B;
        font-size: 46px;
        margin: 0 0 20px;
        text-transform: uppercase;
        pointer-events: none; }
        .complete-training .complete-train .train-session .exercise-active span {
          color: #FFFFFF; }
      .complete-training .complete-train .train-session .exercise-name {
        cursor: pointer;
        font-size: 36px;
        margin: 0 0 20px;
        text-transform: uppercase; }
        .complete-training .complete-train .train-session .exercise-name span {
          color: #FFFFFF; }
        .complete-training .complete-train .train-session .exercise-name.disabled {
          pointer-events: none;
          opacity: .6; }
      .complete-training .complete-train .train-session .train-title {
        font-size: 14px;
        font-weight: 400;
        margin: 80px 0 30px; }
      .complete-training .complete-train .train-session .progress-heading {
        font-size: 26px;
        font-family: 'Freon', sans-serif;
        font-weight: 400;
        text-transform: uppercase;
        text-align: left; }
      .complete-training .complete-train .train-session .train-progress {
        margin: 20px 0 25px; }
        .complete-training .complete-train .train-session .train-progress .level-bar {
          height: 4px;
          background-color: #FFFFFF;
          position: relative; }
          .complete-training .complete-train .train-session .train-progress .level-bar .level-points {
            height: 10px;
            width: 10px;
            background-color: #FFFFFF;
            border-radius: 50%;
            position: absolute;
            top: -3px;
            transform: translateX(-50%); }
          .complete-training .complete-train .train-session .train-progress .level-bar .beginner {
            left: 35%; }
          .complete-training .complete-train .train-session .train-progress .level-bar .advance {
            right: -5px; }
          .complete-training .complete-train .train-session .train-progress .level-bar .medium {
            left: 65%; }
        .complete-training .complete-train .train-session .train-progress .level-value {
          position: absolute;
          left: -2px;
          top: -5px;
          background-color: #FA5C6B;
          width: 15%;
          border-radius: 6px 0 0 6px;
          height: 14px; }
    .complete-training .complete-train.failure {
      text-align: center;
      display: block; }
      .complete-training .complete-train.failure .train-title {
        border-bottom: 2px solid white;
        text-align: left;
        padding-bottom: 2px;
        font-family: 'FF-DIN-BOLD', sans-serif;
        opacity: .6;
        font-size: 18px;
        margin-bottom: 18px;
        max-width: 90%;
        pointer-events: none; }
      .complete-training .complete-train.failure .exercise-active {
        font-size: 46px;
        margin: 0 -46px; }
        .complete-training .complete-train.failure .exercise-active.disabled {
          opacity: 1; }
        .complete-training .complete-train.failure .exercise-active ~ .exercise-active {
          font-size: 36px;
          opacity: .6; }
  .complete-training + .footer {
    background: transparent; }

.complete-training.home-red .complete-train .train-session .slick-slider .slick-dots li.slick-active button,
.complete-training.home-pink .complete-train .train-session .slick-slider .slick-dots li.slick-active button {
  border: 2px solid #01cfd4; }

.complete-training.home-red .complete-train .train-session .exercise-active,
.complete-training.home-pink .complete-train .train-session .exercise-active {
  color: #01cfd4; }

.complete-training.home-red .complete-train .train-session .train-progress .level-value,
.complete-training.home-pink .complete-train .train-session .train-progress .level-value {
  background-color: #01cfd4; }

.complete-training.home-green .complete-train .train-session .slick-slider .slick-dots li.slick-active button {
  border: 2px solid #f44030; }

.complete-training.home-green .complete-train .train-session .exercise-active {
  color: #f44030; }

.complete-training.home-green .complete-train .train-session .train-progress .level-value {
  background-color: #f44030; }

.complete-training.home-blue .complete-train .train-session .slick-slider .slick-dots li.slick-active button {
  border: 2px solid #FF8841; }

.complete-training.home-blue .complete-train .train-session .exercise-active {
  color: #FF8841; }

.complete-training.home-blue .complete-train .train-session .train-progress .level-value {
  background-color: #FF8841; }

.complete-training.home-black .complete-train .train-session .slick-slider .slick-dots li.slick-active button {
  border: 2px solid #D9EE55; }

.complete-training.home-black .complete-train .train-session .exercise-active {
  color: #D9EE55; }

.complete-training.home-black .complete-train .train-session .train-progress .level-value {
  background-color: #D9EE55; }

.complete-training.home-orange .complete-train .train-session .slick-slider .slick-dots li.slick-active button {
  border: 2px solid #01cfd4; }

.complete-training.home-orange .complete-train .train-session .exercise-active {
  color: #01cfd4; }

.complete-training.home-orange .complete-train .train-session .train-progress .level-value {
  background-color: #01cfd4; }

@media only screen and (max-width: 1440px) {
  .complete-training {
    padding: 140px 20px 30px 20px; } }

@media screen and (max-width: 991px) {
  .complete-training .complete-train .train-session {
    padding: 0 5px 0 0; }
    .complete-training .complete-train .train-session .session-heading {
      font-size: 18px; }
      .complete-training .complete-train .train-session .session-heading + p {
        margin: 12px 0 26px; }
    .complete-training .complete-train .train-session .exercise-active {
      font-size: 34px;
      margin: 0 0 12px; }
    .complete-training .complete-train .train-session .exercise-name {
      font-size: 26px;
      margin: 0 0 12px; }
    .complete-training .complete-train .train-session .train-title {
      margin: 40px 0 20px; } }

@media screen and (max-width: 768px) {
  .complete-training {
    padding: 95px 0  0; }
    .complete-training .complete-train {
      width: 100vw;
      display: block; }
      .complete-training .complete-train .train-session {
        grid-row: 1;
        grid-column: 1/3;
        text-align: center; }
        .complete-training .complete-train .train-session .slick-slider {
          display: block; }
        .complete-training .complete-train .train-session .session-heading, .complete-training .complete-train .train-session .session-heading + p {
          text-align: left; }
        .complete-training .complete-train .train-session .exercise-name {
          text-align: left; }
        .complete-training .complete-train .train-session .exercise-active {
          text-align: left; }
        .complete-training .complete-train .train-session .astro-btn {
          margin: 0 10px 0 0;
          width: 130px; }
      .complete-training .complete-train .share-img {
        display: none; } }

@media screen and (orientation: landscape) and (max-width: 840px) and (max-height: 640px) {
  .complete-training {
    padding: 95px 0 0; }
    .complete-training .complete-train {
      padding: 0 20px 20px 20px;
      min-height: calc(100vh - 95px);
      grid-gap: 3%; }
      .complete-training .complete-train .train-session {
        grid-row: 1;
        grid-column: 1/3; }
        .complete-training .complete-train .train-session .train-title {
          display: none; }
        .complete-training .complete-train .train-session .progress-heading {
          display: none; }
        .complete-training .complete-train .train-session .train-progress {
          display: none; }
        .complete-training .complete-train .train-session .astro-btn {
          width: 125px; }
      .complete-training .complete-train .share-img {
        grid-row: 2;
        grid-column: 1/3;
        overflow: hidden;
        height: 100%; } }

.training-header {
  text-align: center;
  margin: 0 0 54px;
  z-index: 2; }
  .training-header .title {
    font-size: 26px; }
  .training-header .heading {
    text-transform: uppercase;
    font-size: 46px; }

.training {
  padding: 120px 15px 100px;
  min-height: 100vh;
  color: #FFFFFF; }
  .training .training-container {
    max-width: 830px;
    margin: 0 auto;
    min-height: calc(100vh - 380px); }
    .training .training-container .train-session {
      font-size: 16px;
      text-align: center; }
    .training .training-container .tabs {
      margin: 40px 0 12px;
      display: grid;
      grid-template-columns: repeat(3, auto);
      grid-column-gap: 10px; }
      .training .training-container .tabs .tab {
        font-size: 14px;
        font-weight: bold;
        padding: 12px 8px;
        border: 2px solid #FFFFFF;
        color: #FFFFFF;
        background-color: transparent;
        border-radius: 27px;
        text-align: center;
        opacity: 0.3; }
        .training .training-container .tabs .tab.active {
          background-color: #F68847;
          border-color: #F68847;
          color: #FFFFFF;
          opacity: 1; }
    .training .training-container .squat {
      border-bottom: 2px solid #3C3C3C; }
      .training .training-container .squat .squat-level {
        font-size: 20px;
        padding: 10px 15px;
        background-color: black;
        text-transform: uppercase;
        font-family: 'FF-DIN-BOLD';
        margin: 2px 0 0 0; }
        .training .training-container .squat .squat-level + .squat-queues {
          border-top: none !important; }
      .training .training-container .squat .squat-queues {
        display: grid;
        grid-template-columns: repeat(2, max-content);
        padding: 20px 15px;
        justify-content: space-between;
        border-top: 2px solid #3C3C3C; }
        .training .training-container .squat .squat-queues .queue-name {
          font-size: 26px;
          font-weight: 400; }
        .training .training-container .squat .squat-queues .adding-queue {
          display: grid;
          grid-template-columns: repeat(2, max-content);
          grid-column-gap: 10px;
          align-items: center; }
          .training .training-container .squat .squat-queues .adding-queue .queue-text {
            font-size: 14px;
            font-weight: bold;
            text-transform: uppercase;
            white-space: nowrap; }
          .training .training-container .squat .squat-queues .adding-queue .queue-icon {
            height: 30px;
            width: 30px;
            display: grid;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            border: 2px solid #F68847;
            cursor: pointer; }
          .training .training-container .squat .squat-queues .adding-queue .remove-icon {
            background-color: #F68847; }
    .training .training-container .astro-btn {
      position: sticky;
      bottom: 50px;
      left: 50%;
      transform: translateX(-50%);
      z-index: 2;
      margin: 20px 0 0 0; }
    .training .training-container .mobo-next-btn {
      display: none; }

.home-gray.training .training-container .tabs .tab.active {
  background-color: #FA5C6B;
  border-color: #FA5C6B; }

.home-gray.training .training-container .squat {
  border-bottom: 2px solid #425E76; }
  .home-gray.training .training-container .squat .squat-level {
    background-color: #0D2B46; }
  .home-gray.training .training-container .squat .squat-queues {
    border-top: 2px solid #425E76; }
    .home-gray.training .training-container .squat .squat-queues .adding-queue .queue-icon {
      background-color: transparent;
      border: 2px solid #FA5C6B; }
    .home-gray.training .training-container .squat .squat-queues .adding-queue .remove-icon {
      background-color: #FA5C6B; }

.home-orange.training .training-container .tabs .tab.active {
  background-color: #01cfd4;
  border-color: #01cfd4; }

.home-orange.training .training-container .squat .squat-level {
  background-color: #1C555F; }

.home-orange.training .training-container .squat .squat-queues .adding-queue .queue-icon {
  background-color: transparent;
  border: 2px solid #01cfd4; }

.home-orange.training .training-container .squat .squat-queues .adding-queue .remove-icon {
  background-color: #01cfd4; }

.home-black.training .training-container .tabs .tab.active {
  background-color: #D9EE55;
  border-color: #D9EE55;
  color: black; }

.home-black.training .training-container .squat .squat-queues .adding-queue .queue-icon {
  background-color: transparent;
  border: 2px solid #D9EE55; }

.home-black.training .training-container .squat .squat-queues .adding-queue .remove-icon {
  background-color: #D9EE55; }

.home-green.training .training-container .tabs .tab.active {
  background-color: #f44030;
  border-color: #f44030; }

.home-green.training .training-container .squat .squat-level {
  background-color: #C93227; }

.home-green.training .training-container .squat .squat-queues .adding-queue .queue-icon {
  background-color: transparent;
  border: 2px solid #f44030; }

.home-green.training .training-container .squat .squat-queues .adding-queue .remove-icon {
  background-color: #f44030; }

.home-pink.training .training-container .tabs .tab.active {
  background-color: #01cfd4;
  border-color: #01cfd4; }

.home-pink.training .training-container .squat .squat-level {
  background-color: #1C555F; }

.home-pink.training .training-container .squat .squat-queues .adding-queue .queue-icon {
  background-color: transparent;
  border: 2px solid #01cfd4; }

.home-pink.training .training-container .squat .squat-queues .adding-queue .remove-icon {
  background-color: #01cfd4; }

@media only screen and (max-width: 768px) {
  .training {
    padding: 120px 0 75px 0; }
    .training .training-container {
      padding: 0 15px; }
      .training .training-container .train-session {
        font-size: 14px; }
      .training .training-container .next-btn {
        display: none; }
      .training .training-container .mobo-next-btn {
        display: block;
        bottom: 25px; }
    .training + .footer {
      display: none; }
  .training-header .title {
    font-size: 22px; }
  .training-header .heading {
    font-size: 30px; } }

@media only screen and (max-width: 768px) {
  .training .training-container .squat .squat-queues {
    height: auto;
    padding: 20px 5px 20px 15px; }
    .training .training-container .squat .squat-queues .queue-name {
      font-size: 20px; }
    .training .training-container .squat .squat-queues .adding-queue .queue-text {
      font-size: 12px; } }

@media only screen and (max-width: 640px) {
  .training {
    padding: 120px 0 125px 0; }
    .training .training-container .squat {
      height: auto; }
      .training .training-container .squat::-webkit-scrollbar {
        width: 0; }
      .training .training-container .squat .squat-queues .adding-queue .queue-text {
        display: none; } }

@media only screen and (max-width: 640px) {
  .training .training-container .tabs {
    grid-column-gap: 5px; } }

#scene-container {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: all .15s ease;
  pointer-events: none;
  overflow: hidden; }
  #scene-container.active {
    opacity: 1; }

#scene {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none; }
  #scene.right {
    left: 25%; }
  #scene.top {
    height: 60vh; }
  #scene.top-landscape {
    height: 70vh; }

.start-training {
  position: relative;
  min-height: 100vh;
  display: grid;
  align-items: center;
  padding: 100px 25px 0 6.5%; }
  .start-training .timer {
    position: absolute;
    top: 50%;
    left: 100px;
    transform: translateY(-50%);
    height: 526px;
    width: 526px;
    z-index: 2; }
    .start-training .timer .timer-circle {
      stroke: #FA5C6B;
      background-color: transparent;
      stroke-width: 2%;
      fill: transparent; }
    .start-training .timer .timer-text {
      padding: 0 25px;
      position: absolute;
      top: 10px;
      left: 10px;
      right: 10px;
      bottom: 10px;
      border-radius: 50%;
      color: #FFFFFF;
      text-align: center;
      display: grid;
      align-content: center;
      justify-items: center; }
      .start-training .timer .timer-text .text-heading {
        font-size: 44px;
        line-height: 1.4;
        text-transform: uppercase;
        font-family: 'FF-DIN-BOLD'; }
      .start-training .timer .timer-text .next-btn {
        display: none; }
      .start-training .timer .timer-text .session {
        font-size: 28px;
        position: absolute;
        top: 80px; }
      .start-training .timer .timer-text .step2-heading {
        font-size: 72px;
        text-transform: uppercase;
        font-family: 'FF-DIN-BOLD'; }
        .start-training .timer .timer-text .step2-heading img {
          max-width: 100%; }
      .start-training .timer .timer-text .step3-check img {
        width: 80%; }
      .start-training .timer .timer-text .step4-heading {
        font-size: 372px; }
      .start-training .timer .timer-text .text-para {
        margin: 20px 0 45px;
        font-size: 26px; }
  .start-training .train-time {
    position: relative;
    z-index: 2;
    color: #FFFFFF;
    top: -100px; }
    .start-training .train-time .time-to {
      font-size: 42px;
      display: inline-block;
      position: relative;
      padding: 0 0 25px;
      font-family: 'FF-DIN-BOLD'; }
      .start-training .train-time .time-to:after {
        content: "";
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: 5px;
        border-radius: 8px;
        background-color: #FA5C6B; }
    .start-training .train-time .repetition {
      margin: 30px 0 0;
      font-size: 75px;
      font-weight: 800; }
  .start-training .loader-wrapper {
    position: absolute;
    left: 40px;
    bottom: 40px;
    display: grid;
    z-index: 2;
    grid-template-columns: repeat(2, max-content);
    color: #FFFFFF;
    align-items: center; }
    .start-training .loader-wrapper .session {
      font-size: 16px; }
    .start-training .loader-wrapper .title {
      font-size: 42px;
      font-family: 'FF-DIN-BOLD';
      text-transform: uppercase; }
    .start-training .loader-wrapper .face-arrow {
      width: 40px; }
      .start-training .loader-wrapper .face-arrow img {
        width: 40px; }
  .start-training .loader {
    height: 126px;
    width: 126px;
    z-index: 1; }
    .start-training .loader .loader-circle {
      stroke: #FA5C6B;
      background-color: transparent;
      stroke-width: 16%;
      fill: transparent; }
    .start-training .loader .loader-value {
      stroke-Width: 5px;
      stroke: #FFFFFF;
      fill: transparent;
      opacity: 0.2; }
  .start-training .overlay {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0; }
  .start-training + .footer {
    background: transparent; }

.loader-transition {
  transition: all 1s linear;
  /* NOTE - This should match the training timer interval */ }

#screen-shot-layers {
  position: absolute;
  top: -999%;
  left: -999%;
  width: 640px;
  height: 480px;
  overflow: hidden; }

.screen-shot-frame {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1; }

.frame-parts {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.frame-part-camera {
  width: 100%;
  height: 100%;
  transform: scale(-1, 1); }

.frame-part-astro {
  background: no-repeat center center / auto 100%; }

.frame-part-ui {
  z-index: 9; }

.screen-shot-camera-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.screen-shot-astro-logo {
  position: absolute;
  top: 12px;
  left: 12px;
  width: 35%; }

.screen-shot-puma-logo {
  position: absolute;
  top: 12px;
  right: 12px;
  width: 10%; }

.screen-shot-footer {
  position: absolute;
  bottom: 12px;
  left: 12px;
  color: white; }
  .screen-shot-footer .session {
    font-size: 16px; }
  .screen-shot-footer .title {
    font-size: 32px;
    font-family: FF-DIN-BOLD, sans-serif;
    text-transform: uppercase; }

.video-container {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  overflow: hidden; }

.video-container video {
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scaleX(-1); }

.home-red-overlay .overlay {
  background: rgba(244, 64, 48, 0.4); }

.home-blue-overlay .overlay {
  background: rgba(0, 161, 175, 0.4); }

.home-orange-overlay .overlay {
  background: rgba(255, 136, 65, 0.4); }

.home-green-overlay .overlay {
  background: rgba(208, 237, 66, 0.4); }

.home-gray-overlay .overlay {
  background: rgba(46, 93, 118, 0.4); }

.home-black-overlay .overlay {
  background: rgba(0, 0, 0, 0.4); }

.home-pink-overlay .overlay {
  background: rgba(250, 92, 107, 0.4); }

.start-training.home-red-overlay .timer .timer-circle {
  stroke: #D9EE55; }

.start-training.home-red-overlay .train-time .time-to:after {
  background-color: #D9EE55; }

.start-training.home-red-overlay .loader .loader-circle {
  stroke: #D9EE55; }

.start-training.home-orange-overlay .timer .timer-circle {
  stroke: #01cfd4; }

.start-training.home-orange-overlay .train-time .time-to:after {
  background-color: #01cfd4; }

.start-training.home-orange-overlay .loader .loader-circle {
  stroke: #01cfd4; }

.start-training.home-pink-overlay .timer .timer-circle {
  stroke: #01cfd4; }

.start-training.home-pink-overlay .train-time .time-to:after {
  background-color: #01cfd4; }

.start-training.home-pink-overlay .loader .loader-circle {
  stroke: #01cfd4; }

.start-training.home-black-overlay .timer .timer-circle {
  stroke: #D0ED42; }

.start-training.home-black-overlay .train-time .time-to:after {
  background-color: #D9EE55; }

.start-training.home-black-overlay .loader .loader-circle {
  stroke: #D9EE55; }

.start-training.home-gray .timer .timer-circle {
  stroke: #F68847; }

.start-training.home-gray .train-time .time-to:after {
  background-color: #F68847; }

.start-training.home-gray .loader .loader-circle {
  stroke: #F68847; }

.start-training.home-green-overlay .timer .timer-circle {
  stroke: #f44030; }

.start-training.home-green-overlay .train-time .time-to:after {
  background-color: #f44030; }

.start-training.home-green-overlay .loader .loader-circle {
  stroke: #f44030; }

.start-training.home-blue-overlay .timer .timer-circle {
  stroke: #F68847; }

.start-training.home-blue-overlay .train-time .time-to:after {
  background-color: #F68847; }

.start-training.home-blue-overlay .loader .loader-circle {
  stroke: #F68847; }

#results, #isExercise {
  position: absolute;
  top: 0;
  left: 0; }

@media screen and (max-width: 1440px) {
  .start-training .timer {
    height: 480px;
    width: 480px; } }

@media screen and (max-width: 1240px) {
  .start-training .timer {
    height: 450px;
    width: 450px; }
  .start-training .train-time .time-to {
    font-size: 46px; }
  .start-training .train-time .repetition {
    margin: 30px 0 0;
    font-size: 60px; } }

@media screen and (max-width: 991px) {
  .start-training .timer {
    height: 380px;
    width: 380px; }
    .start-training .timer .timer-text .text-heading {
      font-size: 32px; }
    .start-training .timer .timer-text .step2-heading {
      font-size: 54px; }
    .start-training .timer .timer-text .step4-heading {
      font-size: 272px; }
    .start-training .timer .timer-text .text-para {
      margin: 10px 0 20px;
      font-size: 18px; }
  .start-training .loader-wrapper .title {
    font-size: 32px; }
  .start-training .train-time .time-to {
    font-size: 46px; }
  .start-training .train-time .repetition {
    font-size: 50px; } }

@media screen and (max-width: 768px) {
  .start-training {
    padding: 80px 25px 40px; }
    .start-training .timer .timer-text .session {
      font-size: 22px;
      top: 40px; }
    .start-training .loader-wrapper .title {
      font-size: 28px; }
    .start-training .train-time {
      top: -80px; }
      .start-training .train-time .time-to {
        font-size: 32px; }
      .start-training .train-time .repetition {
        margin: 25px 0 0;
        font-size: 38px; } }

@media screen and (max-width: 640px) {
  .start-training .timer {
    height: 290px;
    width: 290px;
    left: 50%;
    transform: translate(-50%, -50%); }
    .start-training .timer .timer-text .text-heading {
      font-size: 24px; }
    .start-training .timer .timer-text .step2-heading {
      font-size: 36px; }
    .start-training .timer .timer-text .step4-heading {
      font-size: 172px; }
    .start-training .timer .timer-text .text-para {
      margin: 7px 0 16px;
      font-size: 14px; }
  .start-training .loader-wrapper .title {
    font-size: 24px; }
  .start-training .train-time .time-to {
    font-size: 28px;
    padding: 0px 0 20px; }
  .start-training .train-time .repetition {
    margin: 20px 0 0;
    font-size: 34px; } }

@media screen and (max-width: 480px) {
  .start-training .train-time .time-to {
    font-size: 24px;
    padding: 0px 0 15px; }
  .start-training .train-time .repetition {
    margin: 15px 0 0;
    font-size: 28px; }
  .loader-wrapper .title {
    font-size: 22px; } }

@media screen and (max-width: 385px) {
  .start-training .train-time .repetition {
    font-size: 22px; } }

@media screen and (orientation: landscape) and (max-width: 840px) and (max-height: 640px) {
  .start-training {
    padding: 80px 15px 10px 50px; }
    .start-training .warmUp.timer {
      height: 100%;
      width: 70%;
      right: 0;
      top: 0;
      transform: translate(0, 0); }
      .start-training .warmUp.timer .timer-text {
        text-align: left;
        justify-items: unset;
        padding: 0; }
        .start-training .warmUp.timer .timer-text .text-heading {
          font-size: 28px; }
          .start-training .warmUp.timer .timer-text .text-heading br {
            display: none; }
          .start-training .warmUp.timer .timer-text .text-heading span {
            display: block;
            font-size: 30px;
            font-weight: bolder; }
        .start-training .warmUp.timer .timer-text .astro-btn {
          display: none; }
        .start-training .warmUp.timer .timer-text .next-btn {
          display: block; }
        .start-training .warmUp.timer .timer-text .text-para {
          font-size: 14px;
          margin: 7px 0 16px; }
    .start-training .landScape-hide {
      display: none; }
    .start-training .train-time {
      top: 0px; }
      .start-training .train-time .time-to {
        font-size: 32px;
        padding: 0 0 15px; }
      .start-training .train-time .repetition {
        margin: 20px 0 0;
        font-size: 36px; }
    .start-training .loader-wrapper {
      left: 55px;
      bottom: 20px; }
    .start-training .timer {
      height: 220px;
      width: 220px;
      left: 40px;
      top: 70px;
      transform: translate(0, 0); }
      .start-training .timer .timer-text .step3-check img {
        width: 65%; }
      .start-training .timer .timer-text .step2-heading {
        font-size: 28px; }
      .start-training .timer .timer-text .step4-heading {
        font-size: 140px; } }

.start-train {
  min-height: 100vh;
  position: relative;
  color: #FFFFFF;
  padding: 120px 0 0; }
  .start-train .training-header {
    margin: 0; }
  .start-train .btn-wrapper {
    position: absolute;
    bottom: 90px;
    z-index: 2;
    left: 0;
    right: 0;
    display: grid;
    grid-template-columns: repeat(3, max-content);
    justify-content: center;
    grid-gap: 20px;
    align-items: end; }
    .start-train .btn-wrapper .bg-select, .start-train .btn-wrapper .pop-btn, .start-train .btn-wrapper .shoe-select {
      position: unset;
      transform: translate(0); }
    .start-train .btn-wrapper .astro-btn {
      padding: 11px 15px; }

@media only screen and (orientation: portrait) and (max-width: 768px) {
  .start-train .btn-wrapper .pop-btn {
    position: fixed;
    top: 50%;
    right: 24px;
    left: auto;
    transform: translate(0, -50%);
    display: flex;
    align-items: center;
    justify-content: flex-end; }
  .start-train .btn-wrapper .astro-btn {
    width: auto;
    padding: 7px 20px;
    min-height: 0; } }

@media only screen and (max-width: 640px) {
  .start-train .btn-wrapper {
    grid-gap: 0;
    bottom: 40px; }
    .start-train .btn-wrapper .bg-select, .start-train .btn-wrapper .pop-btn, .start-train .btn-wrapper .shoe-select {
      padding: 0;
      position: absolute;
      bottom: unset;
      right: unset;
      left: unset;
      top: unset; }
    .start-train .btn-wrapper .pop-btn {
      position: fixed;
      top: 50%;
      right: 24px;
      left: auto;
      transform: translate(0, -50%);
      display: flex;
      align-items: center;
      justify-content: flex-end; }
    .start-train .btn-wrapper .astro-btn {
      width: auto;
      padding: 7px 20px;
      min-height: 0; }
    .start-train .btn-wrapper .bg-select {
      left: 150px; }
    .start-train .btn-wrapper .bg-select.shoe-select {
      left: 45px; }
      .start-train .btn-wrapper .bg-select.shoe-select .bg-text {
        grid-column: 1/3; }
    .start-train .btn-wrapper .bg-select {
      min-height: 48px; } }

@media screen and (orientation: landscape) and (max-width: 840px) and (max-height: 640px) {
  .start-train .btn-wrapper {
    bottom: 20px; } }

.shoe-select.bg-select {
  bottom: 110px;
  z-index: 2;
  padding: 5px 15px; }
  .shoe-select.bg-select .shoe-container {
    display: inline-block;
    position: relative; }
    .shoe-select.bg-select .shoe-container:after {
      content: '';
      display: block;
      width: 0;
      height: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border: 1px solid white;
      border-radius: 99px;
      z-index: -1;
      transition: all .25s ease; }
    .shoe-select.bg-select .shoe-container.active-shoe:after {
      width: 1.8em;
      height: 1.8em;
      box-shadow: inset 0 0 5px 0 white; }
  .shoe-select.bg-select .shoe {
    width: 50px;
    cursor: pointer;
    pointer-events: all; }
  .shoe-select.bg-select .shoe1 {
    grid-column: 6; }
  .shoe-select.bg-select .shoe2 {
    grid-column: 7; }
  .select-shoe-active .shoe-select.bg-select .shoe-container {
    opacity: 1;
    pointer-events: auto; }

@media screen and (max-width: 1024px) {
  .shoe-select.bg-select {
    padding: 0 15px; } }

@media only screen and (max-width: 768px) {
  .shoe-select.bg-select {
    left: 50%;
    bottom: 85px; } }

@media only screen and (max-width: 640px) {
  .shoe-select.bg-select {
    grid-template-columns: repeat(2, max-content);
    left: 112px;
    bottom: 40px;
    justify-content: center; }
    .shoe-select.bg-select .shoe-container {
      pointer-events: none;
      opacity: 0; }
    .shoe-select.bg-select .shoe1 {
      grid-column: 1; }
    .shoe-select.bg-select .shoe2 {
      pointer-events: none;
      grid-column: 2; }
    .shoe-select.bg-select .bg-text {
      grid-row: 2;
      grid-column: 1/3; } }

@media only screen and (max-width: 370px) {
  .shoe-select.bg-select {
    left: 100px; }
    .shoe-select.bg-select .bg-text span {
      display: none; } }

@media screen and (orientation: landscape) and (max-width: 840px) and (max-height: 620px) {
  .shoe-select.bg-select {
    left: 115px;
    bottom: 40px;
    grid-template-columns: repeat(2, max-content);
    justify-content: center; }
    .shoe-select.bg-select .shoe-container {
      pointer-events: none;
      opacity: 0; }
    .shoe-select.bg-select .shoe1 {
      grid-column: 1; }
    .shoe-select.bg-select .shoe2 {
      pointer-events: none;
      grid-column: 2; }
    .shoe-select.bg-select .bg-text {
      grid-row: 2;
      grid-column: 1/3; } }

.watch {
  min-height: 100vh;
  position: relative;
  padding: 150px 0 150px;
  overflow: hidden; }
  .watch .slick-slider .slick-prev, .watch .slick-slider .slick-next {
    position: fixed; }
  .watch .slick-slider .slick-prev {
    left: 75px;
    z-index: 1; }
    .watch .slick-slider .slick-prev:before {
      content: "";
      background: url("./../../images/ArrowRight-blue.png") no-repeat;
      height: 32px;
      display: block;
      width: 16px;
      opacity: 1; }
  .watch .slick-slider .slick-next {
    right: 96px; }
    .watch .slick-slider .slick-next:before {
      content: "";
      background: url("./../../images/ArrowLeft-blue.png") no-repeat;
      height: 32px;
      display: block;
      width: 16px;
      opacity: 1; }
  .watch .slick-slider .slick-track, .watch .slick-slider .slick-slider .slick-list {
    min-height: calc(100vh - 210px); }
  .watch .slick-current {
    pointer-events: auto;
    opacity: 1;
    filter: grayscale(0) !important; }
  .watch .slick-slide {
    padding: 0px 60px; }
    .watch .slick-slide .video-wrapper iframe {
      position: relative;
      filter: brightness(0.2); }
    .watch .slick-slide .athlete-name {
      opacity: 0.6; }
    .watch .slick-slide .athlete-info {
      opacity: 0.6; }
  .watch iframe {
    height: 400px; }
  .watch .slick-current {
    pointer-events: auto;
    opacity: 1; }
    .watch .slick-current .video-wrapper iframe {
      filter: brightness(1); }
    .watch .slick-current .athlete-name {
      opacity: 1; }
    .watch .slick-current .athlete-info {
      opacity: 1; }
  .watch .watch-video {
    color: #FFFFFF; }
    .watch .watch-video .content-btn {
      padding: 12px 0;
      width: 148px;
      border-radius: 35px;
      color: #FFFFFF;
      background-color: transparent;
      border: 1px solid #FA5C6B;
      font-size: 14px;
      font-weight: bold; }
    .watch .watch-video .athlete-name {
      margin: 0 0 25px 0;
      font-weight: bold;
      font-size: 46px;
      text-transform: uppercase;
      display: grid;
      grid-template-columns: minmax(170px, 690px);
      font-family: 'FF-DIN-BOLD';
      font-weight: normal; }
    .watch .watch-video .athlete-info {
      font-size: 20px;
      max-width: 693px;
      line-height: 1.6; }

.watch.home-blue .slick-slider .slick-prev:before {
  background: url("./../../images/ArrowRight-orange.png") no-repeat; }

.watch.home-blue .slick-slider .slick-next:before {
  background: url("./../../images/ArrowLeft-orange.png") no-repeat; }

.watch.home-green .slick-slider .slick-prev:before {
  background: url("./../../images/ArrowRight-pink.png") no-repeat; }

.watch.home-green .slick-slider .slick-next:before {
  background: url("./../../images/ArrowLeft-pink.png") no-repeat; }

.watch.home-black .slick-slider .slick-prev:before {
  background: url("./../../images/ArrowRight-green.png") no-repeat; }

.watch.home-black .slick-slider .slick-next:before {
  background: url("./../../images/ArrowLeft-green.png") no-repeat; }

.watch.home-gray .slick-slider .slick-prev:before {
  background: url("./../../images/ArrowRight-pink.png") no-repeat; }

.watch.home-gray .slick-slider .slick-next:before {
  background: url("./../../images/ArrowLeft-pink.png") no-repeat; }

@media only screen and (min-width: 1240px) {
  .watch .watch-video {
    width: 900px; }
    .watch .watch-video iframe {
      height: 600px; } }

@media only screen and (max-width: 991px) {
  .watch .watch-video .athlete-name {
    margin: 0 0 20px 0;
    font-size: 38px; }
  .watch .watch-video .athlete-info {
    font-size: 16px; } }

@media only screen and (max-width: 768px) {
  .watch {
    padding: 120px 0 15px 0; }
    .watch .slick-slide {
      padding: 0px 15px; }
    .watch .slick-slider .slick-track, .watch .slick-slider .slick-list {
      height: 100%; }
    .watch iframe {
      height: 340px; }
    .watch .watch-video .video-wrapper {
      max-height: 400px; }
    .watch .watch-video .athlete-name {
      margin: 10px 0 15px 0;
      font-size: 32px; } }

@media only screen and (max-width: 768px) {
  .watch .watch-video .athlete-name {
    font-size: 20px; } }

@media only screen and (max-width: 640px) {
  .watch .slick-slider .slick-prev, .watch .slick-slider .slick-next {
    display: none !important; }
  .watch .bg-select {
    left: 50%; } }

@media only screen and (max-width: 576px) {
  .watch iframe {
    height: 250px; } }

@media only screen and (max-width: 480px) {
  .watch iframe {
    height: 200px; } }

@media only screen and (max-width: 420px) {
  .watch iframe {
    height: 165px; } }

@media only screen and (max-width: 375px) {
  .watch .watch-video {
    width: 220px; }
  .watch iframe {
    height: 135px; } }

@media only screen and (max-width: 350px) {
  .watch .watch-video {
    width: 190px; } }

@media screen and (orientation: landscape) and (max-width: 840px) and (max-height: 640px) {
  .watch {
    padding: 80px 0 120px; }
    .watch + .footer {
      display: none; } }

@media screen and (orientation: landscape) and (max-width: 1040px) and (max-height: 780px) {
  .watch + .footer {
    display: none; } }

.evolution {
  position: relative;
  min-height: 100vh;
  padding: 180px 0 125px 7.5%; }
  .evolution .evolution-levels {
    display: grid;
    grid-template-columns: 1fr 1fr; }
    .evolution .evolution-levels .levels {
      color: #FFFFFF; }
      .evolution .evolution-levels .levels .mob-levels {
        display: none; }
      .evolution .evolution-levels .levels .level {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        justify-content: space-between;
        grid-gap: 10px;
        margin: 0 0 65px;
        padding: 0 0 0 75px;
        position: relative; }
        .evolution .evolution-levels .levels .level .details-level {
          position: absolute;
          top: 0;
          left: 0;
          height: 30px;
          width: 30px;
          border-radius: 50%;
          background-color: #425E76;
          display: grid;
          align-items: center;
          justify-content: center;
          cursor: pointer; }
        .evolution .evolution-levels .levels .level .train-name {
          font-size: 26px;
          text-transform: uppercase; }
        .evolution .evolution-levels .levels .level .train-level {
          font-size: 20px;
          text-align: right; }
          .evolution .evolution-levels .levels .level .train-level span {
            font-weight: 800;
            display: block; }
        .evolution .evolution-levels .levels .level .level-bar {
          grid-column: 1/3;
          height: 4px;
          background-color: #FFFFFF;
          position: relative; }
          .evolution .evolution-levels .levels .level .level-bar .level-points {
            height: 10px;
            width: 10px;
            background-color: #FFFFFF;
            border-radius: 50%;
            position: absolute;
            top: -3px;
            transform: translateX(-50%); }
          .evolution .evolution-levels .levels .level .level-bar .beginner {
            left: 35%; }
          .evolution .evolution-levels .levels .level .level-bar .advance {
            right: -6px; }
          .evolution .evolution-levels .levels .level .level-bar .medium {
            left: 65%; }
        .evolution .evolution-levels .levels .level .level-value {
          position: absolute;
          left: -2px;
          top: -5px;
          background-color: #425E76;
          width: 2%;
          border-radius: 6px 0 0 6px;
          height: 14px; }
        .evolution .evolution-levels .levels .level .sub-levels {
          margin: 35px 0 0 0;
          grid-column: 1/3; }
          .evolution .evolution-levels .levels .level .sub-levels .sub-level {
            position: relative;
            margin: 0 0 20px;
            padding: 0 0 0 55px;
            align-items: center; }
            .evolution .evolution-levels .levels .level .sub-levels .sub-level .play-sublevel {
              position: absolute;
              top: 0;
              left: 0;
              height: 30px;
              width: 30px;
              border-radius: 50%;
              background-color: #425E76;
              display: grid;
              align-items: center;
              justify-content: center;
              cursor: pointer; }
            .evolution .evolution-levels .levels .level .sub-levels .sub-level .workout-name {
              font-weight: 400;
              font-size: 26px; }
            .evolution .evolution-levels .levels .level .sub-levels .sub-level .level-bar {
              grid-column: initial;
              margin-top: 12px; }
            .evolution .evolution-levels .levels .level .sub-levels .sub-level .advance {
              top: -3px; }
          .evolution .evolution-levels .levels .level .sub-levels .unlock-workout {
            display: grid;
            grid-template-columns: max-content 1fr;
            grid-column-gap: 30px;
            margin: 45px 0 0;
            align-items: center; }
            .evolution .evolution-levels .levels .level .sub-levels .unlock-workout .unlock-para {
              font-size: 20px; }

.demo-playing .exercise-demo {
  opacity: .35; }

.exercise-demo.active {
  opacity: 1; }

@media only screen and (min-width: 768px) {
  .evolution + .footer + #scene-container {
    position: fixed; } }

@media only screen and (max-width: 768px) {
  .evolution {
    padding: 95px 20px 85px 20px; }
    .evolution .evolution-levels .levels {
      grid-row: 2;
      grid-column: 1/3; }
      .evolution .evolution-levels .levels > div > .level {
        display: none; }
      .evolution .evolution-levels .levels .mob-levels {
        display: block; }
        .evolution .evolution-levels .levels .mob-levels .levels-names {
          padding: 20px 0  0; }
          .evolution .evolution-levels .levels .mob-levels .levels-names .level-name {
            font-family: 'FF-DIN', sans-serif;
            font-size: 36px;
            text-transform: uppercase;
            margin: 0 0 3vh 0; }
          .evolution .evolution-levels .levels .mob-levels .levels-names .active {
            color: #425E76; }
        .evolution .evolution-levels .levels .mob-levels .level {
          display: block;
          margin: 0;
          padding: 0; }
          .evolution .evolution-levels .levels .mob-levels .level .details-level {
            left: unset;
            right: 0;
            background-color: transparent;
            border: 2px solid #425E76; }
            .evolution .evolution-levels .levels .mob-levels .level .details-level img {
              height: 15px;
              width: 15px; }
              .evolution .evolution-levels .levels .mob-levels .level .details-level img.cross-icon {
                height: 10px;
                width: 10px; }
          .evolution .evolution-levels .levels .mob-levels .level .train-level {
            text-align: left;
            margin: 0 0 20px 0; }
          .evolution .evolution-levels .levels .mob-levels .level .sub-levels .sub-level {
            padding: 0 0 0 40px;
            grid-template-columns: 1fr 1fr; }
            .evolution .evolution-levels .levels .mob-levels .level .sub-levels .sub-level .level-bar {
              background-color: transparent; }
              .evolution .evolution-levels .levels .mob-levels .level .sub-levels .sub-level .level-bar .level-points {
                background-color: transparent; }
            .evolution .evolution-levels .levels .mob-levels .level .sub-levels .sub-level .details-level {
              right: unset;
              left: 0; }
              .evolution .evolution-levels .levels .mob-levels .level .sub-levels .sub-level .details-level img {
                height: 10px;
                width: 10px; }
            .evolution .evolution-levels .levels .mob-levels .level .sub-levels .sub-level .workout-name {
              font-size: 22px; }
          .evolution .evolution-levels .levels .mob-levels .level .sub-levels .unlock-workout .unlock-para {
            font-size: 16px; }
          .evolution .evolution-levels .levels .mob-levels .level.mobo-level {
            display: grid;
            grid-template-columns: max-content 1fr;
            grid-gap: 20px;
            padding: 40px 0 0;
            margin: 40vh 0 0 0; }
            .evolution .evolution-levels .levels .mob-levels .level.mobo-level .train-name {
              display: block;
              line-height: 1; }
            .evolution .evolution-levels .levels .mob-levels .level.mobo-level .train-level {
              text-align: right;
              margin: 0;
              align-self: end;
              font-size: 16px; }
      .evolution .evolution-levels .levels .level .train-name {
        display: none; }
    .evolution .evolution-levels .levels-ai {
      grid-row: 1;
      grid-column: 1/3; }
  .mobile-expanded .levels-names {
    display: none !important; } }

@media only screen and (max-width: 480px) {
  .evolution .evolution-levels .levels .mob-levels .level .sub-levels .sub-level {
    grid-gap: 15px; }
    .evolution .evolution-levels .levels .mob-levels .level .sub-levels .sub-level .workout-name {
      grid-column: 1/3; }
    .evolution .evolution-levels .levels .mob-levels .level .sub-levels .sub-level .level-bar {
      grid-column: 1/3; } }

.evolution.home-black .evolution-levels .levels .level .details-level {
  background-color: #D9EE55;
  border: 2px solid #D9EE55; }

.evolution.home-black .evolution-levels .levels .level .level-value {
  background-color: #D9EE55; }

.evolution.home-black .evolution-levels .levels .mob-levels .levels-names .active {
  color: #D9EE55; }

.evolution.home-orange .evolution-levels .levels .level .details-level {
  background-color: #01cfd4;
  border: 2px solid #01cfd4; }

.evolution.home-orange .evolution-levels .levels .level .level-value {
  background-color: #01cfd4; }

.evolution.home-orange .evolution-levels .levels .mob-levels .levels-names .active {
  color: #01cfd4; }

.evolution.home-pink .evolution-levels .levels .level .details-level {
  background-color: #01cfd4;
  border: 2px solid #01cfd4; }

.evolution.home-pink .evolution-levels .levels .level .level-value {
  background-color: #01cfd4; }

.evolution.home-pink .evolution-levels .levels .mob-levels .levels-names .active {
  color: #01cfd4; }

.evolution.home-green .evolution-levels .levels .level .details-level {
  background-color: #f44030;
  border: 2px solid #f44030; }

.evolution.home-green .evolution-levels .levels .level .level-value {
  background-color: #f44030; }

.evolution.home-green .evolution-levels .levels .mob-levels .levels-names .active {
  color: #f44030; }

.evolution.home-blue .evolution-levels .levels .level .details-level {
  background-color: #FF8841;
  border: 2px solid #FF8841; }

.evolution.home-blue .evolution-levels .levels .level .level-value {
  background-color: #FF8841; }

.evolution.home-blue .evolution-levels .levels .mob-levels .levels-names .active {
  color: #FF8841; }

.evolution.home-gray .evolution-levels .levels .level .details-level {
  background-color: #FA5C6B;
  border: 2px solid #FA5C6B; }

.evolution.home-gray .evolution-levels .levels .level .level-value {
  background-color: #FA5C6B; }

.evolution.home-gray .evolution-levels .levels .mob-levels .levels-names .active {
  color: #FA5C6B; }

@media screen and (orientation: landscape) and (max-width: 840px) and (max-height: 640px) {
  .evolution .evolution-levels .levels .mob-levels {
    padding: 0 0 0 35px; } }

.bg-select {
  position: fixed;
  bottom: 55px;
  left: 40px;
  border: 2px solid white;
  border-radius: 35px;
  padding: 8px 15px;
  display: grid;
  grid-template-columns: repeat(8, max-content);
  grid-gap: 8px;
  align-items: center;
  z-index: 2;
  pointer-events: none;
  min-height: 42px; }
  .bg-select .bg-text {
    font-family: 'FF-DIN-BOLD';
    color: white;
    font-size: 14px;
    pointer-events: auto; }
  .bg-select .select-btn {
    height: 20px;
    width: 20px;
    display: inline-block;
    border: 1px solid transparent;
    border-radius: 50%;
    cursor: pointer;
    pointer-events: auto; }
  .bg-select .active {
    border: 1px solid white; }
  .select-background-active .bg-select .select-btn {
    opacity: 1;
    pointer-events: auto; }

@media only screen and (max-width: 768px) {
  .bg-select {
    position: absolute;
    left: 50%;
    bottom: 30px;
    transform: translateX(-50%); } }

@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .bg-select,
  .bg-select.shoe-select {
    left: 60px; } }

@media only screen and (max-width: 640px) {
  .bg-select {
    border: none;
    left: 255px;
    bottom: 40px;
    padding: 0;
    background: none; }
    .bg-select .select-btn {
      pointer-events: none;
      opacity: 0; }
    .bg-select .bg-text {
      border: 2px solid white;
      grid-row: 2;
      grid-column: 2/7;
      text-align: center;
      border-radius: 35px;
      padding: 10px 15px; } }

@media screen and (orientation: landscape) and (max-width: 840px) and (max-height: 620px) {
  .bg-select {
    border: none;
    left: 255px;
    bottom: 40px;
    padding: 0;
    background: none; }
    .bg-select .select-btn {
      pointer-events: none;
      opacity: 0; }
    .bg-select .bg-text {
      border: 2px solid white;
      grid-row: 2;
      grid-column: 2/7;
      text-align: center;
      border-radius: 35px;
      padding: 10px 15px; } }

@media screen and (max-width: 370px) {
  .bg-select {
    left: 225px; } }

.footer {
  position: fixed;
  z-index: 1;
  display: grid;
  grid-template-columns: repeat(2, max-content);
  align-items: center;
  padding: 90px 45px 60px 0;
  pointer-events: none;
  left: 0;
  right: 0;
  bottom: 0;
  justify-content: end; }
  .footer .social-icons li {
    display: inline-block; }
    .footer .social-icons li a {
      font-size: 14px;
      font-weight: 800;
      text-transform: uppercase;
      padding: 0 10px;
      pointer-events: auto;
      cursor: pointer; }
      .footer .social-icons li a img {
        margin: 0 0 -6px 12px; }

#mute-audio.muted img {
  opacity: 0.4; }

@media only screen and (min-width: 769px) {
  .home-red-gradiant {
    background: linear-gradient(#C9322700 0%, #C93227 50%); }
  .home-gray-gradiant {
    background: linear-gradient(#0D2B4600 0%, #0D2B46 50%); }
  .home-green-gradiant {
    background: linear-gradient(#899A2100 0%, #899A21 50%); }
  .home-blue-gradiant {
    background: linear-gradient(#1C555F00 0%, #1C555F 50%); }
  .home-black-gradiant {
    background: linear-gradient(#00000000 0%, black 50%); }
  .home-orange-gradiant {
    background: linear-gradient(#93360000 0%, #933600 50%); }
  .home-pink-gradiant {
    background: linear-gradient(#ffffff00 0%, #960815 50%); } }

@media screen and (orientation: landscape) and (max-width: 840px) and (max-height: 640px) {
  .footer {
    background: transparent; } }

@media only screen and (max-width: 768px) {
  .footer {
    padding: 0;
    left: unset;
    right: 10px;
    bottom: 40px; }
    .footer .social-icons li {
      display: none; }
      .footer .social-icons li:last-child {
        display: block; }
        .footer .social-icons li:last-child a {
          text-indent: -9999px;
          display: block; }
          .footer .social-icons li:last-child a img {
            display: block;
            margin: 0 0 10px; } }

.hide {
  display: none; }

.fade {
  opacity: 0;
  pointer-events: none !important; }
